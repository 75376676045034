<template>
  <div class="signup-enter-code">
    <cookies-disabled-error v-if="cookiesDisabledError" />
    <signup-member-already-exists-error v-else-if="memberAlreadyExists" :phoneNumber="phoneNumber" />
    <div v-else>
      <div class="error-creating-customer ri-bold-label" v-if="serverErrors.createCustomer">קרתה תקלה, אפשר לבקש קוד חדש ולנסות שוב</div>
      <div class="content">
        <enter-code class="enter-code"
                    :first-name="firstName"
                    :phone-number="phoneNumber"
                    :confirm-code-action="authenticateAndProceed"
                    :regenerate-code-action="forceGenerateCode"
                    :server-error="serverErrors.authenticate"
                    :button-title="'אישור'"
                    :loading="loading"
                    :signup-text="signupText"
                    :reset-error="() => resetError('authenticate')"/>
      </div>
    </div>
    <login-support-footer-positioned/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import SignupMemberAlreadyExistsError
  from '@/pages/responsive-pages/non-authenticated/wine-and-dine/signup/steps/SignupMemberAlreadyExistsError';
import Segment from '@/Segment';
import EnterCode from '@/base-components/EnterCode.vue';
import CookiesDisabledError from '@/pages/general-errors/CookiesDisabledError.vue';
import LoginSupportFooterPositioned from '@/base-components/LoginSupportFooterPositioned';

export default {
  name: 'SignupEnterCode',
  components: {
    LoginSupportFooterPositioned,
    SignupMemberAlreadyExistsError,
    EnterCode,
    CookiesDisabledError,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    signupText: {
      type: String,
      required: true,
    },
    next: {
      type: Function,
      required: false,
    },
  },
  async created() {
    Segment.trackUserGot(`${this.segmentEventPrefix}SignupCodeEntered`);
    Segment.trackUserGot(`${this.segmentEventPrefix}SignupCodeEntered_Marketing`);
    Segment.trackUserInteractionToPixel(`${this.segmentEventPrefix}SignupCodeEntered`);
  },
  computed: {
    ...mapState('signup', ['firstName', 'lastName', 'email', 'phoneNumber',
      'serverErrors', 'createdCustomerId', 'icrcParam', 'fbcId', 'segmentEventPrefix']),
    ...mapGetters('signup', ['errorTypes', 'amplitudeUtmParams']),
    ...mapState('promotions', ['promotion']),
    ...mapGetters('promotions', ['isPromotionEligible']),
    memberAlreadyExists() {
      return this.serverErrors.createCustomer === this.errorTypes.memberAlreadyExists;
    },
    cookiesDisabledError() {
      return this.serverErrors.authenticate === this.errorTypes.cookiesDisabled;
    },
  },
  methods: {
    ...mapActions('signup', ['authenticate', 'createCustomer', 'forceGenerateCode', 'fetchUtmParamsFromRoute', 'saveCustomerAnswers']),
    ...mapMutations('signup', ['resetError']),
    async authenticateAndProceed(code) {
      Segment.trackUserInteraction(`${this.segmentEventPrefix}CodeEnteredClicked_Marketing`);
      Segment.trackUserInteraction(`${this.segmentEventPrefix}CodeEnteredClicked`);
      Segment.trackUserInteractionToPixel(`${this.segmentEventPrefix}CodeEnteredClicked`);
      this.loading = true;
      try {
        await this.authenticate(code);
        if (!this.serverErrors.authenticate) {
          await this.signupCreateCustomer();
          Segment.trackUserInteractionToPixel(`${this.segmentEventPrefix}CodeEnteredSuccess`);
          await this.next();
        } else if (this.memberAlreadyExists) {
          Segment.trackUserGot(`${this.segmentEventPrefix}SignupEnterCode_MemberAlreadyExists`);
        } else if (this.cookiesDisabledError) {
          Segment.trackUserGot(`${this.segmentEventPrefix}SignupEnterCode_CookiesDisabledError`);
        }
      } catch (e) {
        this.loading = false;
      }
    },
    async identifyCustomer(customerId) {
      Segment.identifyCustomer(customerId, {
        firstName: this.firstName,
        promotion: this.promotion && this.isPromotionEligible ? this.promotion : null,
        ...this.amplitudeUtmParams,
      });
      Segment.trackUserSignedUp(this.segmentEventPrefix, customerId);
    },
    async signupCreateCustomer() {
      this.fetchUtmParamsFromRoute();
      await this.createCustomer();
      await this.identifyCustomer(this.createdCustomerId);
      if (this.productName === 'cashflow') {
        await this.saveCustomerAnswers();
      }
      Segment.trackUserGot(`${this.segmentEventPrefix}CustomerCreated_Marketing`,
        {
          CustomerID: this.createdCustomerId,
          promotion: this.promotion,
          icrc: this.icrcParam,
        });
      Segment.trackUserGotToPixel(`${this.segmentEventPrefix}CCC`, { fbc: this.fbcId }, {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phone: this.phoneNumber,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
  @import 'src/scss/mixins';
  @import "~@riseupil/base-ui/src/scss/riseup-colors";

  .signup-enter-code {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    .image-container {
      width: 100%;
    }

    .error-creating-customer {
      display: flex;
      align-content: center;
      align-items: center;
      height: 40px;
      padding-right: 30px;
      background-color: $riseup_danger_red;
      color: $riseup_white;
    }

    .content {
      margin: 30px;
    }

    .enter-code {
      width: 100%;
    }
  }

</style>
